<template>
    <div class="order-container">
        <div class="order-form">
            <h4 class="h4">充值手机号<span class="span">（请核对好号码，充错需自行协商）</span></h4>
            <input v-model="mobile" class="mobile" placeholder="请输入手机号" type="text" maxlength="11"/>
            <div class="submit-btn" @click="search">查询</div>
        </div>
        <ul class="entries">
            <li class="view-result">
                <div class="result-table-wrap">
                    <div class="li-split"></div>
                    <div class="table-div" v-for="(i,idx) in order_list" :key="i.id">
                        <div class="index">#{{idx+1}}</div>
                        <table class="viewtable" >
                            <tbody>
                            <tr class="cday alt">
                                <th>创建日期</th>
                                <td>{{i.create_time&&i.create_time.split('T')[0]}}</td>
                            </tr>
                            <tr class="cday alt">
                                <th>充值手机号：(请核对好号码，充错需自行协商)</th>
                                <td>{{i.mobile}}</td>
                            </tr>
                            <tr class="cday alt">
                                <th>手机号归属运营商：(不支持携号转网及虚商号)</th>
                                <td>中国{{operObj[i.type_name]}}</td>
                            </tr>
                            <tr class="cday alt">
                                <th>{{operObj[i.type_name]}}手机号充值</th>
                                <td>{{i.original_price / 100}}元联通手机号码充值：¥{{i.price/100}} × {{i.product_num}}</td>
                            </tr>
                            <tr class="cday alt">
                                <th>确认充值：</th>
                                <td>{{['未开始', '充值中','充值成功','充值失败'][i.recharge_state - 1]}}</td>
                            </tr>
                            <tr class="cday alt" v-if="i.refund_state > 0">
                                <th>退款状态：</th>
                                <td>{{['退款成功', '退款失败','退款中','退款中'][i.refund_state - 1]}}</td>
                            </tr>
                            <tr class="cday alt">
                                <th>金额</th>
                                <td>{{i.price*i.product_num/100}}</td>
                            </tr>
                            <tr class="cday alt">
                                <th>在线支付状态</th>
                                <td>{{['未支付','已支付','交易关闭', '已退款'][i.state-1]}}<a v-if="i.state==1" @click="submit(i)" :style="{color: '#2e73ff'}">（马上付款）</a></td>
                            </tr>
                            <tr v-if="i.pay_time" class="cday alt">
                                <th>支付时间</th>
                                <td>{{i.pay_time&&i.pay_time.split('T')[0]||''}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </li>
        </ul>
        <SelectPay v-show="showSelectPay" :totalPrice="totalPrice" @onGoPay="goPay"/>
    </div>
</template>
<script >
    import {orderList,orderReSubmit} from "@/service/recharge"
    import SelectPay from "./recharge/SelectPay.vue"
    export default {
        components: {
            SelectPay
        },
      data(){
          return {
              mobile:localStorage.getItem("mobile")||'',
              order_list:[],
              operObj:{
                  yd:'移动',
                  lt:'联通',
                  dx:'电信',
              },
              showSelectPay: false,
              totalPrice:0,
              submitData: {
                  order_id: '',
                  pay_method: ''
              },
          }
      },
        methods:{
            onBridgeReady(data) {
                // {
                //     "appId": jsapi_sign.appId,                  //公众号名称，由商户传入
                //     "timeStamp": jsapi_sign.timeStamp,        //时间戳，自1970年以来的秒数
                //     "nonceStr": jsapi_sign.nonceStr,           //随机串
                //     "package": jsapi_sign.package,
                //     "signType": jsapi_sign.signType,            //微信签名方式：
                //     "paySign": jsapi_sign.paySign               //微信签名
                // }
                //
                /* eslint-disable */
                console.log(WeixinJSBridge)
                /* eslint-disable */
                WeixinJSBridge && WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', data,
                    function (res) {
                        console.log("WeixinJSBridge res", res);
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                            console.log("支付成功!");
                            // linkToSuccess();
                            // setIsPaid(true);
                        } else {
                            // alert("用户遇到错误或者主动放弃!");
                            console.log("统一处理为用户遇到错误或者主动放弃");
                        }
                    });
            },
            wcPay(config) {


                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                        document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                    }
                } else {
                    this.onBridgeReady(config);
                }
            },
            async search(){
                let {data} = await orderList({
                    mobile:this.mobile
                })
                console.log(data)
                this.order_list=data
            },
            async submit(i) {
                console.log(i)
                let {id,price,product_num}=i;
                this.submitData.order_id = id
                this.totalPrice=price/100*product_num
                this.showSelectPay = true
            },
            async goPay(pay_method) {
                // if(pay_method===3){ //支付宝
                //
                // }
                if (navigator.userAgent.match(/MicroMessenger/i)) {
                    pay_method = 1
                }
                this.submitData.pay_method = pay_method;
                let {config} = await orderReSubmit(this.submitData)
                if (config.pay_url) {
                    window.location.href = config.pay_url
                    return
                }
                this.wcPay(config)
            }
        }
    }
</script>
<style scoped>
    body{
        margin-bottom: 50px;
        background: #eaedf0;
    }
  .order-container{

  }
    .order-form{
        padding:0.5rem ;
        margin: 0 0 0.24rem;
        box-sizing: border-box;
        border-radius: 0.01rem;
        background: #fff;
    }
    .h4{

        font-size: 14px;
        font-weight: bold;
    }
    .mobile{
        box-sizing: border-box;
        margin: 0.5rem 0;
        width: 100%;
        border-radius: 0.1rem;
        padding: 0 0.24rem;

        outline: none;
        height: 1.52rem;
        line-height: 1.52rem;
        border:1px solid #999;
    }
  .submit-btn {


      height: 1.68rem;
      background: #3D7FFF;
      border-radius: 0.84rem;
      text-align: center;
      line-height: 1.68rem;
      font-size: 0.64rem;
      font-family: PingFangSC;
      font-weight: 400;
      color: #FFFFFF;
  }
  .entries{
      padding: 0 0.5rem;
      background: #fff;
  }
  .viewtable {
      display: block;
      border-left: none;
      border-top: 1px solid #b3b8c1;
      border-bottom: 1px solid #b3b8c1;
  }

  .viewtable th {
      width: 35%;
      border: 1px solid #ddd;
      font-weight: normal
  }

  .viewtable td {
      border: 1px solid #ddd
  }

  .viewtable .img {
      width: 100%
  }

  .viewtable>tbody>tr{
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      border-bottom: 1px solid #b3b8c1;
      border-left: 1px solid #b3b8c1;
      border-right: 1px solid #b3b8c1;

  }
  .viewtable>tbody>tr:last-child {
      border-bottom: 0
  }

  .viewtable>tbody>tr>td,.viewtable>tbody>tr>th {
      border: 0;
      background-color: #fff;
      padding: 8px;
      font-size: 14px;
  }

  .viewtable>tbody>tr>th {
      border-right: 1px solid #b3b8c1
  }

  .viewtable>tbody>tr>th {
      font-weight: bold;
      background-color: #f2f3f4;
      /*font-size: 0.28rem;*/
  }

  .viewtable>tbody>tr.table-tr {
      display: block
  }

  .viewtable>tbody>tr.table-tr>th,.viewtable>tbody>tr.table-tr>td {
      display: block;
      width: 100%;
      box-sizing: border-box
  }

  .viewtable>tbody>tr.table-tr>td {
      display: contents
  }

  .viewtable>tbody>tr.hide,.viewtable>tbody>tr.table-tr.hide {
      display: none
  }
  .table-div{
      margin-top: 0.6rem;
  }
    .index{
        line-height: 1.28rem;
        font-size: 14px;
        font-weight: normal;
    }
</style>
